import Field, { FieldConfig } from "./field";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import wordCount from "../utils/words-count";
import loGet from "lodash.get";
import { getAssetURL, getEntryWebUrl } from "../utils/helpers";

const { richTextFromMarkdown } = require("@contentful/rich-text-from-markdown");
const TurndownService = require("turndown");

const inlines: string[] = [
  INLINES.EMBEDDED_ENTRY,
  INLINES.ENTRY_HYPERLINK,
  INLINES.ASSET_HYPERLINK,
];
const inlineHyperLink: string[] = [
  INLINES.ENTRY_HYPERLINK,
  INLINES.ASSET_HYPERLINK,
];
/**
 * This class uses a trick/hack to convert embedded rich text fields
 * 1. Converting from RTE Object to HTML
 *  Using contentful's rich text to html renderer package we convert all embedded content tags
 *  to img tags. This is because the package doesn't convert contentful's own embedded items to a valid HTML.
 *  The src attribute of img contains all information
 *
 * 2. Converting from HTML to RTE object
 *  Since content doesn't provide a package to convert HTML to RTE we use the markdown package, it converts
 *  all the tags except img tags and calls the `processUnhandledNode` function for them, using this hack
 *  we extract the information stored in the src attribute and construct the node
 *
 *  !!!!! POTENTIAL ISSUES !!!!!
 *  Later contentful markdown package might start handling img tags and would never call processUnhandledNode
 *  breaking our conversion code.
 *
 *  ### Why only img?? ###
 *  No other tags were found to be failing and creating custom tag like <random-tag> didn't call processUnhandledNode
 *  function.
 *
 *  Only in case of inline entry/asset hyperlink we are using anchor tag in place of image tags.
 *  The img tag shows just ids of elements when rendered in rich text for review.
 */
export default class RichTextField extends Field {
  constructor(config: FieldConfig) {
    super(config);
    this.in = this.in.bind(this);
  }

  renderNode(nodeType: string, node: any) {
    let content = loGet(node, "content.0.value", "");
    let id = loGet(node, "data.target.sys.id");
    let src = `${nodeType}##${id}`;
    if (inlineHyperLink.includes(nodeType)) {
      return `<a href = '${src}'>${content} </a>`;
    }
    return `<img src="${src}" alt="${content}" />`;
  }

  renderTable(node: any, next: any) {
    let nextContent = next(node.content);
    let finalContent = nextContent.replace(/"/g, "'");
    return `<img src = "table" alt ="${finalContent}">`;
  }

  out() {
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node: any) =>
          this.renderNode(BLOCKS.EMBEDDED_ENTRY, node),
        [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
          this.renderNode(BLOCKS.EMBEDDED_ASSET, node),
        [INLINES.EMBEDDED_ENTRY]: (node: any) =>
          this.renderNode(INLINES.EMBEDDED_ENTRY, node),
        [INLINES.ENTRY_HYPERLINK]: (node: any) =>
          this.renderNode(INLINES.ENTRY_HYPERLINK, node),
        [INLINES.ASSET_HYPERLINK]: (node: any) =>
          this.renderNode(INLINES.ASSET_HYPERLINK, node),
        [BLOCKS.TABLE]: (node: any, next: any) => this.renderTable(node, next),
        [BLOCKS.TABLE_ROW]: (node: any, next: any) =>
          `<tr>${next(node.content)}</tr>`,
        [BLOCKS.TABLE_HEADER_CELL]: (node: any, next: any) =>
          `<th>${next(node.content)}</th>`,
        [BLOCKS.TABLE_CELL]: (node: any, next: any) =>
          `<td>${next(node.content)}</td>`,
      },
    };
    return documentToHtmlString(this.config.source, options);
  }

  processUnhandledNode(node: any) {
    const processGeneralNode = (node: any) => {
      const [type, link] = node.url.split("##");

      if (type && link) {
        let content: any[] = [];

        // Check if the node has alternative content
        if (node.alt) {
          content.push({
            data: {},
            marks: [],
            nodeType: "text",
            value: node.alt,
          });
        }

        const data = {
          data: {
            target: {
              sys: {
                id: link,
                type: "Link",
                linkType: type.toLowerCase().includes("asset")
                  ? "Asset"
                  : "Entry",
              },
            },
          },
          content,
          nodeType: type,
        };

        // Check if the node type is an inline element
        if (inlines.includes(type)) {
          return {
            content: [data],
            data: {},
            nodeType: "paragraph",
          };
        }

        return data;
      }

      return null;
    };

    const processTableNode = (node: any) => {
      const htmlString = node.alt;
      const tableContent = extractTableContent(htmlString);
      const tableNode = {
        data: {},
        content: tableContent,
        nodeType: "table",
      };
      return tableNode;
    };

    const extractTableContent = (htmlString: any) => {
      const trPattern = /<tr>(.*?)<\/tr>/g;
      const trMatches = htmlString.match(trPattern);
      if (!trMatches) return [];

      return trMatches.map((trMatch: any) => {
        return processRow(trMatch);
      });
    };

    const processRow = (trMatch: any) => {
      const thPattern = /<th>(.*?)<\/th>/g;
      const tdPattern = /<td>(.*?)<\/td>/g;
      const rowContent: any = [];

      const thMatches = trMatch.match(thPattern) || [];
      const tdMatches = trMatch.match(tdPattern) || [];

      thMatches.forEach((thMatch: any) => {
        const cellNode = createCellNode(thMatch, "table-header-cell");
        rowContent.push(cellNode);
      });

      tdMatches.forEach((tdMatch: any) => {
        const cellNode = createCellNode(tdMatch, "table-cell");
        rowContent.push(cellNode);
      });

      return {
        data: {},
        content: rowContent,
        nodeType: "table-row",
      };
    };

    const createCellNode = (match: any, nodeType: any) => {
      const paraPattern = /<p>(.*?)<\/p>/g;
      const paraMatches = match.match(paraPattern) || [];
      const paraNode = getNestedTableInParagraph(paraMatches);
      return {
        data: {},
        content: paraNode,
        nodeType: nodeType,
      };
    };

    const getNestedTableInParagraph = (paraMatches: any) => {
      const imgPattern = /<img(.*?)>/g;
      const anchorPattern = /<a (.*?)>(.*?)<\/a>/g;
      const paraContent: any = [];

      paraMatches.forEach((paraMatch: any) => {
        if (paraMatch.match(imgPattern)) {
          const imgNode = processImageNode(paraMatch);
          paraContent.push(imgNode);
        } else if (paraMatch.match(anchorPattern)) {
          const anchorData = processAnchorNode(paraMatch);
          paraContent.push(anchorData);
        } else {
          const textNode = processTextNode(paraMatch);
          paraContent.push(textNode);
        }
      });
      const paraData: any[] = paraContent.map((item: any) => {
        if (item.nodeType === "paragraph") {
          return item;
        } else {
          return {
            data: {},
            content: [item],
            nodeType: "paragraph",
          };
        }
      });

      return paraData;
    };

    const processImageNode = (imgMatch: any) => {
      const regex = /src=['"]([^'"]+)['"]/;
      const match = imgMatch.match(regex);
      if (!match) return null;
      const srcValue = match[1];
      const node = {
        type: "image",
        title: "",
        url: srcValue,
        alt: "",
      };
      return processGeneralNode(node);
    };

    const processAnchorNode = (anchor: any) => {
      const hrefMatch = anchor.match(/href\s*=\s*['"]([^'"]+)['"]/);
      const href = hrefMatch ? hrefMatch[1] : "";
      const value = anchor.replace(/<.*?>/g, "");
      const marks = extractMarks(anchor);
      return {
        nodeType: "hyperlink",
        data: { uri: href },
        content: [{ nodeType: "text", value: value, marks, data: {} }],
      };
    };

    const extractMarks = (text: any) => {
      const boldPattern = /<b>(.*?)<\/b>/g;
      const codePattern = /<code>(.*?)<\/code>/g;
      const italicPattern = /<i>(.*?)<\/i>/g;
      const underlinePattern = /<u>(.*?)<\/u>/g;
      const marks = [];
      if (boldPattern.test(text)) marks.push({ type: "bold" });
      if (codePattern.test(text)) marks.push({ type: "code" });
      if (italicPattern.test(text)) marks.push({ type: "italic" });
      if (underlinePattern.test(text)) marks.push({ type: "underline" });
      return marks;
    };

    const processTextNode = (paraMatch: any) => {
      const marks = extractMarks(paraMatch);
      const text = paraMatch.replace(/<.*?>/g, "");
      return {
        data: {},
        marks,
        value: text,
        nodeType: "text",
      };
    };

    if (node.url === "table") {
      return processTableNode(node);
    } else if (node.url) {
      return processGeneralNode(node);
    }
  }

  async in() {
    const turnDownService = new TurndownService();
    const markdown = turnDownService.turndown(this.config.target);
    return await richTextFromMarkdown(markdown, this.processUnhandledNode);
  }

  getWordCount(): Number {
    return wordCount(documentToPlainTextString(this.config.source));
  }
}
