import loGet from 'lodash.get';
import milify from 'millify';
import { EntryContentStore } from '../../../lambda/src/utils/data-manager/data.manager';

export const ENTRY_LEVEL = 'entry-level';
export const FIELD_LEVEL = 'field-level';

export const STATUS_READY_PUBLISH = 'Ready to publish';
export const STATUS_PUBLISHED = 'Published';
export const STATUS_CHANGED = 'Changed';
export const STATUS_READY_REVIEW = 'Ready for review';
export const STATUS_IN_PROGRESS = 'In progress';
export const STATUS_PENDING = 'Pending';
export const STATUS_OUT_OF_DATE = 'Out of date';
export const STATUS_FAILED = 'Failed';
export const STATUS_NEW = 'New';
export const STATUS_DRAFT = 'Draft'

export const localizationMethods = [
  { id: FIELD_LEVEL, name: 'Field-level localization' },
  { id: ENTRY_LEVEL, name: 'Entry-level localization' },
];

const statusColors: { [any: string]: string } = {
  [STATUS_CHANGED]: 'var(--color-primary)',
  [STATUS_PUBLISHED]: 'var(--color-positive)',
  [STATUS_READY_PUBLISH]: 'var(--color-green-dark)',
  [STATUS_READY_REVIEW]: 'var(--color-blue-dark)',
  [STATUS_IN_PROGRESS]: 'var(--color-warning)',
  [STATUS_FAILED]: 'var(--color-negative)',
  [STATUS_NEW]: 'var(--color-text-base)',
  [STATUS_OUT_OF_DATE]: 'var(--color-ice-dark)',
  [STATUS_PENDING]: '#bbb',
  [STATUS_DRAFT]: 'var(--color-warning)'
};

const statusBackgroundColors: { [any: string]: string } = {
  [STATUS_CHANGED]: 'var(--color-blue-lightest)',
  [STATUS_PUBLISHED]: 'var(--color-green-lightest)',
  [STATUS_READY_PUBLISH]: 'var(--color-green-lightest)',
  [STATUS_READY_REVIEW]: 'var(--color-blue-lightest)',
  [STATUS_IN_PROGRESS]: 'var(--color-orange-lightest)',
  [STATUS_FAILED]: 'var(--color-red-lightest)',
  [STATUS_NEW]: 'var(--gray-300)',
  [STATUS_OUT_OF_DATE]: 'var(--color-ice-mid)',
  [STATUS_PENDING]: '#edf4fc',
  [STATUS_DRAFT]: 'var(--color-orange-lightest)'
};

const statusWeights: { [any: string]: number } = {
  [STATUS_PUBLISHED]: 20,
  [STATUS_READY_PUBLISH]: 34,
  [STATUS_READY_REVIEW]: 40,
  [STATUS_IN_PROGRESS]: 5,
  [STATUS_FAILED]: 0,
  [STATUS_NEW]: 1,
  [STATUS_OUT_OF_DATE]: 0,
  [STATUS_DRAFT]: 5,
};

export interface File {
  entryId: string;
  wordCount: number;
}
export const getTotalWords = (fileData: any) => {
  const entryIds: string[] = [];
  fileData = fileData || [];
  const totalWords = fileData.reduce((wordCount: number, file: File) => {
    if (!entryIds.includes(file.entryId)) {
      wordCount += file.wordCount;
      entryIds.push(file.entryId);
    }
    return wordCount;
  }, 0);
  return milify(totalWords, { precision: 3 });
};

export const getStatusColor = (status: string) => {
  return statusColors[status];
};

export const getStatusBackgroundColor = (status: string) => {
  return statusBackgroundColors[status];
};

export const getStatusWeight = (status: string) => {
  return statusWeights[status];
};

export const isChangedStatus = (translationInfo: any) => {
  const entriesForUpdate = translationInfo.entriesForUpdate || [];
  return translationInfo.success && entriesForUpdate.length > 0;
};

export const getTranslationProjectStatus = (
  translationInfo: any,
  entryContentStore: EntryContentStore,
  changedStatusCallback: Function | null = null,
  language: string | null = null,
) => {
  let status = {
    text: STATUS_PENDING,
    color: getStatusColor(STATUS_PENDING),
    backgroundColor: getStatusBackgroundColor(STATUS_PENDING),
  };

  let translatedEntries: any[] = loGet(entryContentStore, 'translatedEntries', []);
  let fileData: any[] = loGet(entryContentStore, 'fileData', []);
  if (language) {
    translatedEntries = translatedEntries.filter((translatedEntry: any) => {
      return translatedEntry.target == language;
    });
    fileData = fileData.filter((fileEntry: any) => {
      return fileEntry.target == language;
    });
  }

  const inProgressEntries = fileData.reduce((acc: number, entry: any) => {
    return entry.status == STATUS_IN_PROGRESS ? acc + 1 : acc;
  }, 0);

  const readyPublishEntries = translatedEntries.reduce((acc: number, entry: any) => {
    return entry.status == STATUS_READY_PUBLISH ? acc + 1 : acc;
  }, 0);

  const publishedEntries = translatedEntries.reduce((acc: number, entry: any) => {
    return entry.status == STATUS_PUBLISHED ? acc + 1 : acc;
  }, 0);

  const translatedEntriesWithoutStatus = translatedEntries.reduce((acc: number, entry: any) => {
    return !entry.status ? acc + 1 : acc;
  }, 0);

  let isStatusChanged: boolean;
  if (changedStatusCallback) {
    isStatusChanged = changedStatusCallback();
  } else {
    isStatusChanged = isChangedStatus(translationInfo);
  }
  // there were some source entries already - the project was submitted - check for changed status
  if (isStatusChanged) {
    status = {
      text: STATUS_CHANGED,
      color: getStatusColor(STATUS_CHANGED),
      backgroundColor: getStatusBackgroundColor(STATUS_CHANGED),
    };
  } else if (fileData.length && fileData.length == publishedEntries) {
    status = {
      text: STATUS_PUBLISHED,
      color: getStatusColor(STATUS_PUBLISHED),
      backgroundColor: getStatusBackgroundColor(STATUS_PUBLISHED),
    };
  } else if (translatedEntries.length && readyPublishEntries > 0) {
    status = {
      text: STATUS_READY_PUBLISH,
      color: getStatusColor(STATUS_READY_PUBLISH),
      backgroundColor: getStatusBackgroundColor(STATUS_READY_PUBLISH),
    };
  } else if (translatedEntriesWithoutStatus) {
    status = {
      text: STATUS_READY_REVIEW,
      color: getStatusColor(STATUS_READY_REVIEW),
      backgroundColor: getStatusBackgroundColor(STATUS_READY_REVIEW),
    };
  } else if (inProgressEntries > 0) {
    status = {
      text: STATUS_IN_PROGRESS,
      color: getStatusColor(STATUS_IN_PROGRESS),
      backgroundColor: getStatusBackgroundColor(STATUS_IN_PROGRESS),
    };
  } else if (fileData.length) {
    status = {
      text: STATUS_NEW,
      color: getStatusColor(STATUS_NEW),
      backgroundColor: getStatusBackgroundColor(STATUS_NEW),
    };
  }
  return status;
};

export const getPerStatusProjectInfo = (
  translationInfo: any,
  entryContentStore: EntryContentStore,
  language: string | null = null,
) => {
  const statusList = [
    STATUS_FAILED,
    STATUS_OUT_OF_DATE,
    STATUS_NEW,
    STATUS_IN_PROGRESS,
    STATUS_READY_REVIEW,
    STATUS_READY_PUBLISH,
    STATUS_PUBLISHED,
  ];

  const translatedEntries = loGet(entryContentStore, 'translatedEntries', []).filter(
    (translatedEntry: any) => {
      return !language || translatedEntry.target == language;
    },
  );
  const translatedEntryIds = translatedEntries.map((translatedEntry: any) => {
    return translatedEntry.entryId + translatedEntry.target;
  });
  const fileData = loGet(entryContentStore, 'fileData', []).filter((fileEntry: any) => {
    return !language || fileEntry.target == language;
  });
  const sourceEntriesCount =
    loGet(entryContentStore, 'sourceEntries', []).length *
    (language ? 1 : loGet(translationInfo, 'entry.targetLanguages', []).length);

  const fileDataCount = fileData.length;
  let perStatusInfo: any = {};

  for (let status of statusList) {
    perStatusInfo[status] = {
      count: 0,
      percentage: 0,
      color: '',
    };
  }

  perStatusInfo[STATUS_FAILED]['count'] =
    sourceEntriesCount - fileDataCount <= 0 ? 0 : sourceEntriesCount - fileDataCount;
  perStatusInfo[STATUS_OUT_OF_DATE]['count'] = 0; // Not implemented
  perStatusInfo[STATUS_NEW]['count'] = fileData.reduce((acc: number, entry: any) => {
    return !entry.status && !translatedEntryIds.includes(entry.entryId + entry.target)
      ? acc + 1
      : acc;
  }, 0);
  perStatusInfo[STATUS_IN_PROGRESS]['count'] = fileData.reduce((acc: number, entry: any) => {
    return entry.status == STATUS_IN_PROGRESS &&
      !translatedEntryIds.includes(entry.entryId + entry.target)
      ? acc + 1
      : acc;
  }, 0);

  perStatusInfo[STATUS_READY_REVIEW]['count'] = translatedEntries.reduce(
    (acc: number, entry: any) => {
      return !entry.status ? acc + 1 : acc;
    },
    0,
  );
  perStatusInfo[STATUS_READY_PUBLISH]['count'] = translatedEntries.reduce(
    (acc: number, entry: any) => {
      return entry.status === STATUS_READY_PUBLISH ? acc + 1 : acc;
    },
    0,
  );
  perStatusInfo[STATUS_PUBLISHED]['count'] = translatedEntries.reduce((acc: number, entry: any) => {
    return entry.status === STATUS_PUBLISHED ? acc + 1 : acc;
  }, 0);

  let factor = 0;
  for (let status of statusList) {
    factor = factor + getStatusWeight(status);
    perStatusInfo[status]['percentage'] = Math.round(
      (perStatusInfo[status]['count'] / fileDataCount) * factor,
    );
    perStatusInfo[status]['color'] = getStatusColor(status);
  }
  return perStatusInfo;
};

export const LOCALIZATION_METHOD = 'localizationMethod';

interface translationProjectFieldType {
	id: string,
  name: string,
  type: string,
  localized: boolean,
  required: boolean,
	validations?: [],
  disabled: boolean,
  omitted: boolean,
}

export const LOCALIZATION_METHOD_OBJECT: translationProjectFieldType = {
  id: LOCALIZATION_METHOD,
  name: 'Localization method',
  type: 'Symbol',
  localized: false,
  required: true,
  disabled: false,
  omitted: false,
};

export const SOURCE_CONTENT = 'sourceContent';
export const EMBEDDED_REFERENCE = 'embeddedReference';
export const SHOW_LINKED_REFERENCE = 'showLinkedReference';
export const SELECTED_PROGRAM = 'selectedProgram';
export const SELECTED_CONTENT = 'selectedContent';
export const EXCLUDED_CONTENT = 'excludedContent';

export const SELECTED_CONTENT_OBJECT: translationProjectFieldType = {
  id: SELECTED_CONTENT,
  name: 'Selected Content',
  type: 'Object',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
}

export const EXCLUDED_CONTENT_OBJECT: translationProjectFieldType = {
  id: EXCLUDED_CONTENT,
  name: 'Excluded Content',
  type: 'Object',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
}

export const EMBEDDED_REFERENCE_OBJECT: translationProjectFieldType = {
  id: EMBEDDED_REFERENCE,
  name: 'Embedded Reference',
  type: 'Boolean',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};

export const SELECTED_PROGRAM_OBJECT: translationProjectFieldType = {
  id: SELECTED_PROGRAM,
  name: 'Selected Program',
  type: 'Integer',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
}

export const SELECTED_EMBEDDED_REFERENCE_OBJECT: translationProjectFieldType = {
  id: 'selectedEmbeddedReferences',
  name: 'Selected Embedded References',
  type: 'Object',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};

export const SHOW_LINKED_REFERENCE_OBJECT: translationProjectFieldType = {
  id: SHOW_LINKED_REFERENCE,
  name: 'Show Linked Reference',
  type: 'Boolean',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};

export const TRANSLATE_SLUG_OBJECT: translationProjectFieldType = {
  id: 'translateSlug',
  name: 'Translate Slug',
  type: 'Boolean',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};

export const SOURCE_CONTENT_OBJECT: translationProjectFieldType = {
  id: SOURCE_CONTENT,
  name: 'Source Content',
  type: 'Object',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};

/**
 * Used as a map to keep track of all the linked content types in structure
 * parent-content-type:[childs-content-type],
 * which will be used while selecting and deselecting the linked content types.
 */
export const LINKED_CONTENT_TYPES_OBJECT: translationProjectFieldType = {
  id: 'linkedContentTypes',
  name: 'Linked Content Types',
  type: 'Object',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};

export default function (
  name: string = 'Translation',
  id: string = 'translation',
  translatorModelName: string,
): any {
  return {
    sys: {
      id: id,
      type: 'ContentType',
    },
    name: name,
    description: '',
    displayField: 'translationName',
    fields: [
      {
        id: 'translationName',
        name: 'Name',
        type: 'Symbol',
        localized: false,
        required: true,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: 'contentType',
        name: 'Content type',
        type: 'Symbol',
        localized: false,
        required: true,
        disabled: false,
        omitted: false,
      },
      LOCALIZATION_METHOD_OBJECT,
      {
        id: 'translator',
        name: 'Translator',
        type: 'Link',
        localized: false,
        required: true,
        validations: [
          {
            linkContentType: [translatorModelName],
          },
        ],
        disabled: false,
        omitted: false,
        linkType: 'Entry',
      },
      {
        id: 'targetLanguages',
        name: 'Target languages',
        type: 'Array',
        localized: false,
        required: true,
        validations: [],
        disabled: false,
        omitted: false,
        items: {
          type: 'Symbol',
          validations: [],
        },
      },
      SOURCE_CONTENT_OBJECT,
      {
        id: 'requestedDueDate',
        name: 'Requested due date',
        type: 'Symbol',
        localized: false,
        required: false,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: 'translationNotes',
        name: 'Additional notes',
        type: 'Text',
        localized: false,
        required: false,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: 'translationInfo',
        name: 'Translation status',
        type: 'Object',
        localized: false,
        required: false,
        validations: [],
        disabled: true,
        omitted: true,
      },
      {
        id: 'localizedReferences', // the field name doesn't match because we changed the field label
        name: 'Linked References',
        type: 'Boolean',
        localized: false,
        required: false,
        validations: [],
        disabled: false,
        omitted: false,
      },
      EMBEDDED_REFERENCE_OBJECT,
      {
        id: 'trackChanges',
        name: 'Track Changes',
        type: 'Boolean',
        localized: false,
        required: false,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: 'selectedReferenceFields',
        name: 'Selected Reference Fields',
        type: 'Object',
        localized: false,
        required: false,
        validations: [],
        disabled: false,
        omitted: false,
      },
      SELECTED_EMBEDDED_REFERENCE_OBJECT,
      SHOW_LINKED_REFERENCE_OBJECT,
      LINKED_CONTENT_TYPES_OBJECT,
      TRANSLATE_SLUG_OBJECT,
      SELECTED_PROGRAM_OBJECT
    ],
  };
}
